<template>
  <div class="no-case-conent">
    <!--左右分屏(试题答题卡)-->
    <div class="Split-screen">
      <div class="lf">
        <div class="Hd">【批改结果】1.1 集合的概念及表示</div>
        <div class="questions-list">
          <div style="height: 1600px"></div>
        </div>
      </div>
      <div class="rg">
         <div class="flex-3">
           <div class="flex-lf"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" style="border: 1px solid #c4e7ff;padding: 0px 10px; height:24px;line-height: 24px; background-color: #37AEFF; color:#c4e7ff" plain round>返回</el-button>
           </div>
           <div class="flex-center">学生：沈力瑞（df1101）</div>
           <div class="flex-rg"> <el-button @click="back()" style="border: 1px solid #F77134; padding: 4px 10px 4px 10px; background-color: #F77134; color:#FFF" type="primary" size="mini" plain round>确定提交</el-button></div>
         </div>
         <div class="Answer-sheet">
           <div style="height: 1600px"></div>
         </div>

      </div>
    </div>

  </div>

</template>


<script>

//import {getBooksObj} from '@/api/courseware'   //路径
export default {
  name: '',
  data() {
    return {
      par:'',
      IwantQuestion:false,//我要提问-弹框
      prolisFrom:{
        prol:''
      },
      QuestionFrom:{
        prol:''
      },
      showQuestion: true,//展开收起
      indexPic: 0,
      heightpx: '320px',
      url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList:[
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      ],

      picList: [{
        id: 0,
        picUrl: require('../../assets/images/pic/1.jpg'),
        text: '这是一'
      },
        {
          id: 1,
          picUrl: require('../../assets/images/pic/2.jpg'),
          text: '这是二'
        },
        {
          id: 2,
          picUrl: require('../../assets/images/pic/3.jpg'),
          text: '这是三'
        },
        {
          id: 3,
          picUrl: require('../../assets/images/pic/4.jpg'),
          text: '这是四'
        }],
      currentIndex: 0, // 选项卡当前的索引
      list: [{
        id: 1,
        question: '一课一练的题为什么每次都刷都不一样呢，why？',
        stuname:'李晓明',
        questiontime:'2021-10-26  15:37',
        discussquantity:2,
        interaction:[
          {
            headportraitId:1,
            intername:'张三',
            intertime:'2021-10-26  15:37',
            swer:'学历案的一课一练的练习题，据我刷题经验，应该有2到3套，而且难度是一次次递增的。',
            answered:2,
            thumbsup:20,
            interlist:[
              {
                headportraitId:1,
                intername:'胡美丽',
                intertime:'2021-10-26  15:37',
                swer:'原来这样，我也发现这个问题了！ //  @吴小丽：到底是我们的班长，厉害。',
                answered:0,
                thumbsup:12,}
            ]
          }
        ],
        path: 'img/apple.png'
      },{
        id: 2,
        question: '沁园春*长沙的创作背景是什么呢？',
        stuname:'张三东',
        questiontime:'2021-12-02  15:37',
        discussquantity:5,
        path: 'img/orange.png'
      },{
        id: 3,
        question: '沁园春*长沙读起来很容易理解，但是为什么练习题这么难呢？',
        stuname:'王老五',
        questiontime:'2021-12-30  15:37',
        discussquantity:0,
        path: 'img/lemon.png'
      }]
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.par=this.$route.query.par;
    this.clickFlag=this.$route.query.clickFlag;
  },
  components: {

  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
    change: function(index){
      // 在这里实现选项卡切换操作：本质就是操作类名
      // 如何操作类名？就是通过currentIndex
      this.currentIndex = index;
    },
    setActiveItem (i) {
      if (this.$refs.carousel) {
        this.$refs.carousel.setActiveItem(i)
      }
    },
    changeCarousel (e) {
      this.ce1 = e+1
      this.ce2 = this.picList.length
      console.log(this.ce1,'000')
      console.log(this.ce2,'111')
      if(this.ce1<this.ce2 || this.ce1==this.ce2) {
        this.$refs['scroll'].wrap.scrollTop = 114  //想滚到哪个高度，就写多少
        this.indexPic = e
      } else {
        this.$refs['scroll'].wrap.scrollTop = 0  //想滚到哪个高度，就写多少
        this.indexPic = e
        console.log('90')
      }

    },
    clickItem (index) {
      this.indexPic = index.id
      this.setActiveItem(index.id)
    },
    tabChange(val) {
      this.activeIndex = val;
      this.$router.push({   //新增一个参数
        query:{tabsid:val}
      });

    },
    //查询列表
    listObj() {
      this.listData = this.list
    },
    //我要提问-弹框
    IwantQuestionObj() {
      this.IwantQuestion = true
    }
  },

}
</script>
<style lang="scss" scoped>
.no-case-conent {
  width: 1300px;
  margin: 20px auto;
  opacity: 0.9;
  /*左右分屏(试题答题卡)*/
  .Split-screen {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .lf {
      width: 50%;
      background-color: #FFF;
      box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
      justify-content: flex-start;
      position: relative;

      .Hd {
        width: 100%;
        height: 36px;
        background-color: #37AEFF;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
      }
      .questions-list {
        overflow-y: auto;
        height: 94%;
      }

    }

    .rg {
      width: 49%;
      background-color: #FFF;
      box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
      box-sizing: border-box;
      justify-content: flex-end;

      .flex-3 {
        width: 96%;
        height: 35px;
        border-bottom:1px dashed #FF6820;
        font-size: 14px;
        margin: auto auto 10px auto;
        display: flex;
        align-items: center;
        .flex-lf {
          width: 70px;
        }
        .flex-center {
          width: 60%;
          flex: 1;
          justify-content: flex-start;
        }
        .flex-rg {
          width: 80px;
          justify-content: flex-end;
        }
      }
      .Answer-sheet {
        overflow-y: auto;
        background-color: #42b983;
      }


    }
  }




}

.intlist {
  width: 98%;
  margin: auto;
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid #AFE6FF;
  .portrait {
    width: 6%;
    justify-content: flex-start;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
  }
  .intR {
    width: 94%;
    justify-content: flex-end;
    .tR01 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      .lf {
        width: 50%;
        color: #999999;
        text-align: left;
        justify-content: flex-start;
      }
      .rg {
        width: 50%;
        color: #999999;
        text-align: right;
        justify-content: flex-end;
      }
    }
    .tR02 {
      width: 100%;
    }
    .tR03 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      div {
        vertical-align: middle;
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}
.Lintlist {
  width: 94%;
  margin: auto 1% auto auto;
  padding: 10px 0;
  display: flex;
  float: right;
  border-bottom: 1px solid #AFE6FF;
  .portrait {
    width: 6%;
    justify-content: flex-start;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }
  }
  .intR {
    width: 94%;
    justify-content: flex-end;
    .tR01 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      .lf {
        width: 50%;
        color: #999999;
        text-align: left;
        justify-content: flex-start;
      }
      .rg {
        width: 50%;
        color: #999999;
        text-align: right;
        justify-content: flex-end;
      }
    }
    .tR02 {
      width: 100%;
    }
    .tR03 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      div {
        vertical-align: middle;
        img {
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}

.active{
  border:3px solid #FF9100;
}
.scrollList{
  width:100%;
}
.pic-item{
  width:230px;
  height: 98px;
  background-color: red;
  margin-bottom: 10px;
}
.scroll-left{
  width:250px;
  height: 320px;
  float: left;
  .scrollbar{
    z-index: 1;
    height: 320px;
    //position: absolute;
    //left: 0;
    //overflow-x: hidden;
    //overflow-y: scroll;


  }
  ::v-deep.el-scrollbar {
    height: 100%;
  }
  ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    width: 100%;
    height: 120%;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #999;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #999;
}

</style>
